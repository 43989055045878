import React, { Component } from 'react'
import { Link } from 'gatsby'

export default class Result extends Component {
  render() {
    const page = this.props.page
    let link = this.props.page.slug
    switch (this.props.page.type) {
      case 'person':
        link = 'people'
        break
      default:
        link = link.replace('/pages', '')
        link = link.indexOf('privacy') !== -1 ? '/privacy-policy' : link
    }
    return (
      <Link to={link} className='result-item'>
        {page.title}
      </Link>
    )
  }
}
