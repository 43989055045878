const path = require('path');

module.exports = {
  siteMetadata: {
    baseTitle: 'Place management and cultural development',
    separator: '|',
    siteUrl: `https://dionysus.place`,
    title: 'Dionysus',
    image: '/img/dionysus_charcoalbackground.jpg',
    description:
      'Events, place management and cultural development towards meaningful places',
  },
  plugins: [
    'gatsby-plugin-sharp',
    'gatsby-plugin-image',
    'gatsby-transformer-sharp',
    {
      resolve: 'gatsby-plugin-brotli',
      options: {
        extensions: ['css', 'js', 'svg', 'json'],
      },
    },
    {
      resolve: 'gatsby-transformer-remark',
      options: {
        plugins: [
          {
            resolve: `gatsby-remark-relative-images`,
          },
        ],
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/src/img`,
        name: 'images',
      },
    },
    {
      // keep as first gatsby-source-filesystem plugin for gatsby image support
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/static/img`,
        name: 'uploads',
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/content`,
        name: 'pages',
      },
    },
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-sass',
    {
      resolve: 'gatsby-plugin-advanced-sitemap',
      options: {
        exclude: [
          /secret-projects/, // you can also pass valid RegExp to exclude internal tags for example
        ],
      },
    },
    {
      resolve: 'gatsby-plugin-root-import',
      options: {
        '@': path.join(__dirname, 'src'),
        styles: path.join(__dirname, 'src/styles'),
        img: path.join(__dirname, 'static/img'),
      },
    },
    {
      resolve: 'gatsby-plugin-google-tagmanager',
      options: {
        id: 'GTM-KQC6XS2',
        includeInDevelopment: false,
      },
    },
    {
      resolve: 'gatsby-plugin-flexsearch',
      options: {
        languages: ['en'],
        type: 'MarkdownRemark',
        fields: [
          {
            name: 'id',
            indexed: false,
            resolver: 'id',
            store: true,
          },
          {
            name: 'html',
            indexed: true,
            resolver: 'internal.content',
            attributes: {
              encode: 'balance',
              tokenize: 'strict',
              threshold: 0,
              resolution: 3,
              depth: 3,
            },
            store: true,
          },
          {
            name: 'title',
            indexed: true,
            resolver: 'frontmatter.title',
            attributes: {
              encode: 'extra',
              tokenize: 'full',
              threshold: 1,
              resolution: 3,
            },
            store: true,
          },
          {
            name: 'description',
            indexed: true,
            resolver: 'frontmatter.description',
            attributes: {
              encode: 'icase',
              tokenize: 'forward',
              threshold: 2,
              depth: 3,
            },
            store: true,
          },
          {
            name: 'type',
            indexed: false,
            resolver: 'frontmatter.type',
            store: true,
          },
          {
            name: 'slug',
            indexed: false,
            resolver: 'fields.slug',
            store: true,
          },
          {
            name: 'layout',
            indexed: false,
            resolver: 'frontmatter.layout',
            store: true,
          },
        ],
      },
    },
    {
      resolve: 'gatsby-plugin-netlify-cms',
      options: {
        manualInit: true,
        modulePath: `${__dirname}/src/cms/cms.js`,
      },
    },
    'gatsby-plugin-netlify', // make sure to keep it last in the array
  ],
};
