import React from 'react';
import { Link } from 'gatsby';

import logo from '@/img/dionysus-logo-bigger.png';
import Search from '@/components/Search/Search';

import URLS from '@/data/UrlsData';

export default class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '',
      content: '',
      navigation: [],
      isMenuOpen: false,
      isNavigationVisible: false,
      navbarScrolled: false,
    };
    this.activateMenu = this.activateMenu.bind(this);
    this.deactivateMenu = this.deactivateMenu.bind(this);
    this.changeBackground = this.changeBackground.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.deactivateMenu);
    window.addEventListener('scroll', this.changeBackground);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.deactivateMenu);
    window.removeEventListener('scroll', this.changeBackground);
  }

  activateMenu() {
    this.setState((prevState) => ({
      isMenuOpen: !prevState.isMenuOpen,
    }));
  }

  deactivateMenu() {
    this.setState({
      isMenuOpen: false,
    });
  }

  changeBackground() {
    if (window.scrollY >= 92) {
      this.setState({
        navbarScrolled: true,
      });
    } else {
      this.setState({
        navbarScrolled: false,
      });
    }
  }

  render() {
    const activeClass = 'nav-link--active';

    let btnClass =
      'btn btn-nav' + (this.state.isMenuOpen ? ' btn-nav--active' : '');
    let brandClass =
      'navbar__logo brand' + (this.state.isMenuOpen ? ' brand--white' : '');
    let navClass =
      'mobile-nav' + (this.state.isMenuOpen ? ' mobile-nav--active' : '');
    let headerClass =
      'header' + (this.state.navbarScrolled ? ' header--dark' : '');

    return (
      <header className={headerClass}>
        <nav className='navigation'>
          <div className='container'>
            <div className='navigation__inner'>
              <Link to='/' className={brandClass}>
                <img src={logo} alt='Dionysus' />
              </Link>

              <div className='navigation__list'>
                <Link
                  to='/projects'
                  className='nav-link'
                  activeClassName={activeClass}>
                  Projects
                </Link>
                <a href='https://www.thevault.place/' className='nav-link'>
                  Vault
                </a>
                <a href={URLS.soulsDefender} className='nav-link'>
                  Soul Defender
                </a>
                <Link
                  to='/people'
                  className='nav-link'
                  activeClassName={activeClass}>
                  People
                </Link>
                <a
                  href='https://6hhb5dh8aal.typeform.com/to/kHJHmyNV'
                  className='nav-link'>
                  Submit an idea
                </a>
                <Link
                  to='/contact'
                  className='nav-link'
                  activeClassName={activeClass}>
                  Contact
                </Link>
                <div className='nav-link'>
                  <Search></Search>
                </div>
              </div>

              <div
                className={btnClass}
                aria-label='Activate mobile menu'
                role='button'
                onClick={this.activateMenu}
                tabIndex={-5}
                onKeyDown={this.activateMenu}>
                <div className='btn-nav__inner'>
                  <div className='btn-nav__element'></div>
                  <div className='btn-nav__element'></div>
                </div>
              </div>
            </div>
          </div>
        </nav>

        <div className={navClass}>
          <div className='mobile-nav__nav'>
            <Link
              to='/'
              className='nav-link nav-link--large'
              activeClassName={activeClass}>
              Home
            </Link>
            <Link
              to='/projects'
              className='nav-link nav-link--large'
              activeClassName={activeClass}>
              Projects
            </Link>
            <a href={URLS.soulsDefender} className='nav-link nav-link--large'>
              Soul Defender
            </a>
            <Link
              to='/people'
              className='nav-link nav-link--large'
              activeClassName={activeClass}
              partiallyActive>
              People
            </Link>
            <a
              className='nav-link nav-link--large'
              href='https://6hhb5dh8aal.typeform.com/to/kHJHmyNV'>
              Submit an idea
            </a>
            <Link
              to='/contact'
              className='nav-link nav-link--large'
              activeClassName={activeClass}>
              Contact
            </Link>
          </div>
        </div>
      </header>
    );
  }
}
