import aos from 'aos'

const SiteHelper = {
  resolve(path, obj, separator = '.') {
    var properties = Array.isArray(path) ? path : path.split(separator)
    let result = properties.reduce((prev, curr) => prev && prev[curr], obj)
    return result ? result : '-'
  },
  test() {
    return 'Henlo'
  },
  initAOS() {
    aos.init({
      duration: 900,
      once: true,
    })
  },
  refreshAOS() {
    aos.refreshHard()
  },
}

export default SiteHelper
