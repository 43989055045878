import React from 'react';
export default function Decorators() {
  return (
    <div className='decorators__wrapper'>
      <div className='container'>
        <div className='decorators'>
          <div className='decorator'></div>
          <div className='decorator'></div>
          <div className='decorator'></div>
          <div className='decorator'></div>
          <div className='decorator'></div>
        </div>
      </div>
    </div>
  );
}
